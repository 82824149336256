<template>
  <div>
    <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" height="4.5rem" width="7rem"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart';
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        // 设置点的位置(经纬度)
        // const geoCoordMap = {
        //   厦门市: [118.11022, 24.490474, 20],
        //   福州市: [119.206239, 26.275302, 20],
        //   泉州市: [118.589421, 24.908853, 20],
        //   漳州市: [117.561801, 24.510897, 20],
        //   龙岩市: [116.82978, 25.391603, 20],
        //   莆田市: [119.007558, 25.591011, 20],
        //   三明市: [117.435001, 26.465444, 20],
        //   南平市: [118.178459, 27.535627, 20],
        //   宁德市: [119.527082, 27.15924, 20],
        // };

        var geoCoordMap = {
          '安徽省': [117.194778, 31.86577],
          '北京市': [116.403694, 39.949459],
          '重庆市': [106.553263, 29.556681],
          '福建省': [119.292069, 26.144144],
          '甘肃省': [103.856737, 36.094212],
          '广东省': [113.239359, 23.185545],
          '广西壮族自治区': [108.345678, 22.861984],
          '贵州省': [106.616332, 26.707352],
          '海南省': [110.350983, 19.968035],
          '河北省': [114.508772, 38.083783],
          '河南省': [113.644099, 34.769161],
          '黑龙江省': [126.522207, 45.801617],
          '湖北省': [114.361594, 30.601078],
          '湖南省': [112.926605, 28.217167],
          '吉林省': [125.326383, 43.797768],
          '江苏省': [118.832137, 32.038322],
          '江西省': [115.851775, 28.672488],
          '辽宁省': [123.486653, 41.682522],
          '内蒙古自治区': [111.785972, 40.849642],
          '宁夏回族自治区': [106.257585, 38.482579],
          '青海省': [101.851432, 36.622494],
          '山东省': [117.194778, 36.652148],
          '山西省': [112.595453, 37.858034],
          '陕西省': [109.026378, 34.350591],
          '上海市': [121.518142, 31.211845],
          '四川省': [104.132697, 30.561282],
          '天津市': [117.286764, 39.001295],
          '西藏自治区': [91.144205, 29.649484],
          '新疆维吾尔自治区': [87.667116, 43.817754],
          '云南省': [102.881681, 24.866897],
          '浙江省': [120.211934, 30.274265],
          '香港特别行政区': [114.242011, 22.272474],
          '澳门特别行政区': [113.579709, 22.169692],
          '台湾省': [121.591732, 25.034634],
        }

        let seriesData = [{ "name": "安徽省" }, { "name": "北京市" }, { "name": "重庆市" }, { "name": "福建省" }, { "name": "甘肃省" }, { "name": "广东省" }, { "name": "广西壮族自治区" }, { "name": "贵州省" }, { "name": "海南省" }, { "name": "河北省" }, { "name": "河南省" }, { "name": "黑龙江省" }, { "name": "湖北省" }, { "name": "湖南省" }, { "name": "吉林省" }, { "name": "江苏省" }, { "name": "江西省" }, { "name": "辽宁省" }, { "name": "内蒙古自治区" }, { "name": "宁夏回族自治区" }, { "name": "青海省" }, { "name": "山东省" }, { "name": "山西省" }, { "name": "陕西省" }, { "name": "上海市" }, { "name": "四川省" }, { "name": "天津市" }, { "name": "西藏自治区" }, { "name": "新疆维吾尔自治区" }, { "name": "云南省" }, { "name": "浙江省" }, { "name": "香港特别行政区" }, { "name": "澳门特别行政区" }, { "name": "台湾省" }]

        // let seriesData = [
        //   {
        //     name: '厦门市',
        //   },
        //   {
        //     name: '福州市',
        //   },
        //   {
        //     name: '泉州市',
        //   },
        //   {
        //     name: '漳州市',
        //   },
        //   {
        //     name: '龙岩市',
        //   },
        //   {
        //     name: '莆田市',
        //   },
        //   {
        //     name: '三明市',
        //   },
        //   {
        //     name: '南平市',
        //   },
        //   {
        //     name: '宁德市',
        //   },
        // ];
        let convertData = function (data) {
          let scatterData = [];
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              scatterData.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value),
              });
            }
          }
          return scatterData;
        };
        this.options = {
          showLegendSymbol: true,
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: point => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            /*
              formatter: params => {
                return `<div style=""> ... </div>`
              }
            */
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
            },
          },
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: '10%',
            left: '16%',
            map: '全国',
            roam: false,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(7,114,204, .8)',
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
              emphasis: {
                areaColor: '#00aeef',
              },
            },
          },
          series: [
            {
              name: '充电桩',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.6,
              mapType: 'china', // 自定义扩展图表类型
              top: '25%',
              left: '20%',
              itemStyle: {
                normal: {
                  color: 'red',
                  areaColor: 'rgba(19,54,162, .5)',
                  borderColor: 'rgba(0,242,252,.3)',
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: '#00f2fc',
                },
                emphasis: {
                  areaColor: '#4f7fff',
                  borderColor: 'rgba(0,242,252,.6)',
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc',
                },
              },
              label: {
                formatter: params => `${params.name}`,
                show: true,
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#efefef',
                },
                emphasis: {
                  textStyle: {
                    color: '#fff',
                  },
                },
              },
              data: newData,
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              symbolSize: 7,
              effectType: 'ripple',
              legendHoverLink: false,
              showEffectOn: 'render',
              rippleEffect: {
                period: 4,
                scale: 2.5,
                brushType: 'stroke',
              },
              zlevel: 1,
              itemStyle: {
                normal: {
                  color: '#99FBFE',
                  shadowBlur: 5,
                  shadowColor: '#fff',
                },
              },
              data: convertData(seriesData),
            },
          ],
        };
        // 重新选择区域
        this.handleMapRandomSelect();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 开启定时器
    startInterval() {
      const _self = this;
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000;
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea();
      }, time);
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = 9;
      this.$nextTick(() => {
        const map = this.$refs.centreLeft2ChartRef.chart;
        let index = Math.floor(Math.random() * length);
        while (index === this.preSelectMapIndex || index >= length) {
          index = Math.floor(Math.random() * length);
        }
        map.dispatchAction({
          type: 'mapUnSelect',
          seriesIndex: 0,
          dataIndex: this.preSelectMapIndex,
        });
        map.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index,
        });
        map.dispatchAction({
          type: 'mapSelect',
          seriesIndex: 0,
          dataIndex: index,
        });
        this.preSelectMapIndex = index;
      });
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        const map = this.$refs.centreLeft2ChartRef.chart;
        const _self = this;
        setTimeout(() => {
          _self.reSelectMapRandomArea();
        }, 0);
        // 移入区域，清除定时器、取消之前选中并选中当前
        map.on('mouseover', function (params) {
          clearInterval(_self.intervalId);
          map.dispatchAction({
            type: 'mapUnSelect',
            seriesIndex: 0,
            dataIndex: _self.preSelectMapIndex,
          });
          map.dispatchAction({
            type: 'mapSelect',
            seriesIndex: 0,
            dataIndex: params.dataIndex,
          });
          _self.preSelectMapIndex = params.dataIndex;
        });
        // 移出区域重新随机选中地图区域，并开启定时器
        map.on('globalout', function () {
          _self.reSelectMapRandomArea();
          _self.startInterval();
        });
        this.startInterval();
      });
    },
  },
};
</script>

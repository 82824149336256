<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">今日营业数据</span>
          <dv-decoration-3 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <CenterLeft1Chart />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div class="item-box" v-for="(item,index) in numberData" :key="index">
          <div class="d-flex">

            <dv-digital-flop :config="item.number" style="width:2.5rem;height:.625rem;" />
          </div>
          <p class="text" style="text-align: center;">
            {{item.text}}
            <span class="colorYellow">({{item.unit}})</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
import http from '@/utils/request.js'
export default {
  data() {
    return {
      numberData: [
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "今日充值",
          unit:'单',
        },
        {
          number: {
            number: [0],
            toFixed: 2,
            content: "{nt}"
          },
          text: "今日充值",
          unit:'元',
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "今日订单",
          unit:'单',
        },
        {
          number: {
            number: [0],
            toFixed: 2,
            content: "{nt}"
          },
          text: "今日订单",
          unit:'元',
        }
      ]
    };
  },
  components: {
    CenterLeft1Chart
  },
  mounted() {
    this.changeTiming();
    this.changeNumber();
  },
  methods: {
    changeTiming() {
      setInterval(() => {
        this.changeNumber();
      }, 60*1000);
    },
    changeNumber() {
      http.get('/today_data').then(res =>{
          const {
            data
          } = res;
          this.numberData[0].number.number[0] = data.recharge.num
          this.numberData[1].number.number[0] = parseFloat(data.recharge.sum)
          this.numberData[2].number.number[0] = parseInt(data.orders.num)
          this.numberData[3].number.number[0] = parseFloat(data.orders.sum)
          this.numberData[0].number = { ...this.numberData[0].number }
          this.numberData[1].number = { ...this.numberData[1].number }
          this.numberData[2].number = { ...this.numberData[2].number }
          this.numberData[3].number = { ...this.numberData[3].number }
        })
    }
  }
};
</script>

<style lang="scss">
#centerLeft1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>
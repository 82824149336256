import axios from 'axios'

// 创建 axios 实例
const http = axios.create({
  baseURL: 'https://api.mx-ai.com/dashboard/data', // url = base url + request url
  timeout: 10000, // request timeout
  withCredentials: true
})

//  请求拦截器
http.interceptors.request.use(
  (config) => {
   
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    const res = response.data
    //届时根据后端返回success或者code值判断
    if (res.status === 'success') {
      return res
    } else {
      return res
    }
  },
  (error) => {
    //响应错误
    return Promise.reject(error)
  }
)

export default http
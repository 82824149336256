<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
        <p class="ml-3 colorBlue fw-b">{{item.title}}</p>
        <div>
          <dv-digital-flop :config="item.number" style="width:1.25rem;height:.625rem;" />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1">单桩前10排行榜</span>
        <dv-scroll-ranking-board :config="ranking" style="height:2.75rem" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>CPU使用率</span>
          <CenterChart :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />
        </div>
        <div class="item bg-color-black">
          <span>内存使用率</span>
          <CenterChart :id="rate[1].id" :tips="rate[1].tips" :colorObj="rate[1].colorData" />
        </div>
        <div class="water">
          <dv-water-level-pond :config="water" style="height: 1.5rem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from "@/components/echart/center/centerChartRate";
import http from '@/utils/request.js'
export default {
  data () {
    return {
      titleItem: [
        {
          title: "在线设备数",
          number: {
            number: [1098],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "运营设备数",
          number: {
            number: [1665],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "设备总数",
          number: {
            number: [2145],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今日充电中",
          number: {
            number: [14],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "本月充电数",
          number: {
            number: [106],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今年充电数",
          number: {
            number: [15999],
            toFixed: 0,
            content: "{nt}"
          }
        }
      ],
      ranking: {
        data: [
          
        ],
        carousel: "single",
        unit: "元"
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ]
    };
  },
  mounted() {
    setInterval(()=>{
        http.get('/system_info').then(res =>{
          const {
            data
          } = res;
          this.rate[0].tips = data.cpu_usage
          this.rate[1].tips = data.mem_usage
        })
      },5000)
      
      http.get('/charging_data').then(res =>{
          const {
            data
          } = res;
          this.titleItem = data
        })
      http.get('/charging_ranking').then(res =>{
          const {
            data
          } = res;
          this.ranking = {
            data: data,
            carousel: "single",
            unit: "元"
          }
        })
  },
  components: {
    CenterChart
    // centerChart1,
    // centerChart2
  }
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      height: 0.875rem;
    }
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 3.1875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 59%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>
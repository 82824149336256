<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">实时订单数据</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board :config="config" style="width:100%;height:4.28rem" />
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/request.js'
export default {
  data() {
    return {
      config: {
        header: ['用户', '时长','金额','时间'],
        data: [
          // ["组件1", "dev-1", "<span  class='colorGrass'>↑75%</span>"],
          // ["组件2", "dev-2", "<span  class='colorRed'>↓33%</span>"],
          // ["组件3", "dev-3", "<span  class='colorGrass'>↑100%</span>"],
          // ["组件4", "rea-1", "<span  class='colorGrass'>↑94%</span>"],
          // ["组件5", "rea-2", "<span  class='colorGrass'>↑95%</span>"],
          // ["组件6", "fix-2", "<span  class='colorGrass'>↑63%</span>"],
          // ["组件7", "fix-4", "<span  class='colorGrass'>↑84%</span>"],
          // ["组件8", "fix-7", "<span  class='colorRed'>↓46%</span>"],
          // ["组件9", "dev-2", "<span  class='colorRed'>↓13%</span>"],
          // ["组件10", "dev-9", "<span  class='colorGrass'>↑76%</span>"]
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [50],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.getChargingOrder();

    setInterval(()=>{
        this.getChargingOrder();
    },10000)
  },
  methods: {
    getChargingOrder(){
      http.get('/new_order_list').then(res =>{
          const {
              data
            } = res
          let _tmp = []
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            _tmp.push([item.userName,item.hours,"<span  class='colorRed'>"+item.total_price+"</span>",item.time])
          }
          this.config = {
            header: ['用户', '时长','金额','时间'],
            data: _tmp,
            rowNum: 7, //表格行数
            headerHeight: 35,
            headerBGC: "#0f1325", //表头
            oddRowBGC: "#0f1325", //奇数行
            evenRowBGC: "#171c33", //偶数行
            index: true,
            columnWidth: [50],
            align: ["center"]
          }
          
        })
    }
  }
};
</script>

<style lang="scss">
#centerRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>